import { createGlobalStyle } from 'styled-components'
import { Breakpoints } from './breakpoints'
import { Colors } from './colors'
import { Typography } from './typography'

export const GlobalStyles = createGlobalStyle`
  * {
    line-height: normal;
    letter-spacing: normal;
    box-sizing: border-box;
  }
  html {
    //break words with hyphens
    hyphens: auto;
    word-wrap: break-word;
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    @media screen and (max-width: ${Breakpoints.lg}) { // width < 1280
      font-size: 56.25%; //1 rem = 9px
    }
    @media screen and (max-width: ${Breakpoints.sm}) { // width < 768
        font-size: 50%; //1 rem = 8px
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    border: 0;
    cursor: pointer;
    background: none;
    outline: 0;
  }
  input,
  textarea,
  select {
    outline: 0;
    margin: 0;
    appearance: none;
    box-shadow: none;
    border: none;
  }
  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    background-clip: padding-box;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
  }
  body {
    margin: 0;
    font-family: ${Typography.family};
    background-color: ${Colors.gray._100};
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: ${Breakpoints.md}) {
      width: 100vw !important;
    }
  }
  .m-auto {
    margin: 0 auto;
  }
  .m-t-48 {
    margin-top: 48px;
  }
  .overflow {
    overflow: auto;
    padding-right: 8px;
    &::-webkit-scrollbar {
      display: block;
      max-width: 4px;
      border-radius: 32px;
      background-color: transparent;
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      border: none;
      border-radius: 32px;
      background-color: ${Colors.gray._200};
    }
  }
  .empty {
    display: flex;
    flex: 1;
    flex-direction: row !important;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    > *:last-of-type {
      margin-right: 15px;
    }
  }
`
