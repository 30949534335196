import styled from 'styled-components'
import { Colors, H4, P1, Typography } from '../../styles'

export const View = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gray._600};
  font-family: ${Typography.family};
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${H4} {
    margin: 0 auto 1.2rem auto;
  }

  ${H4}, ${P1} {
    color: ${Colors.gray._000};
    text-align: center;
  }
`
