import styled from 'styled-components'
import { Colors, Typography } from '../../styles'

const { gray, primary } = Colors
const { size, weight } = Typography

export const ViewProfile = styled.div`
  color: ${gray._000};
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  min-width: 3.2rem;
  font-size: ${size.s1};
  line-height: ${size.s3};
  font-weight: ${weight.semiBold};
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
  justify-content: center;
  background-color: ${primary._000};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`
