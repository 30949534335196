export const Breakpoints = {
  xxl: '1536px',
  xl: '1366px',
  lg: '1280px',
  md: '1024px',
  sm: '768px',
  xs: '600px',
  xxs: '400px',
  containerSizes: {
    xxl: '1440px',
    xl: '1140px',
    lg: '960px',
    md: '720px',
    sm: '540px',
  },
}
